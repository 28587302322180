import React from 'react'
import PopUp from '../components/PopUp'

const NotFound = () => {
  return (
    <div>
      
    </div>
  )
}

export default NotFound